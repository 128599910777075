import { createSlice } from '@reduxjs/toolkit'
import { clientService } from '../../utils/Service'

const categoryInitialState = {
  categoryData: [],
  isLoading: true,
}

const categoriesSlice = createSlice({
  name: 'category',
  initialState: categoryInitialState,
  reducers: {
    setCategoryData: (state, action) => {
      state.categoryData = action.payload
      state.isLoading = false
    },
  },
})

export const getCategoryData = () => async (dispatch) => {
  try {
    const res = await clientService.get('product')
    dispatch(categoriesSlice.actions.setCategoryData(res?.data))
  } catch (error) {
    console.error('Error fetching Category data:', error)
  }
}

export const { setCategoryData } = categoriesSlice.actions

export default categoriesSlice.reducer
