import React from 'react'
import { Navigate } from 'react-router-dom'

const PrivateRoute = ({ children }) => {
  // const [authLoaded, setAuthLoaded] = useState(false)
  const isUserLoggedIn = localStorage.getItem('token')

  // useEffect(() => {
  //   const checkAuth = async () => {
  //     setTimeout(() => {
  //       setAuthLoaded(true)
  //     }, 1000)
  //   }

  //   checkAuth()
  // }, [])

  // if (!authLoaded) {
  //   return <div>Loading...</div>
  // }

  return isUserLoggedIn ? children : <Navigate to="/login" replace />
}

export default PrivateRoute
