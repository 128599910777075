import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Typography,
  Button,
  Modal,
  Box,
  TextField,
  Grid,
  IconButton,
  CircularProgress,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import Pagination from '@mui/material/Pagination'
import CloseIcon from '@mui/icons-material/Close'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { clientService } from '../../utils/Service'
import { getCategoryData } from '../../store/slices/categoriesSlice'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${theme.breakpoints.up('sm')} th`]: {
    fontSize: '1.25rem',
    backgroundColor: '#000',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const Products = () => {
  const dispatch = useDispatch()
  const { categoryId } = useParams()
  const categories = useSelector((state) => state.category.categoryData)
  const _product = categories?.data?.find((item) => item.id === categoryId)

  const getAppData = useCallback(async () => {
    dispatch(getCategoryData())
  }, [dispatch])

  useEffect(() => {
    getAppData()
  }, [getAppData])

  const [pageState, setPageState] = useState({
    isModalOpen: false,
    title: '',
    unit: '',
    loading: false,
    titleError: '',
    unitError: '',
    currentPage: 1,
  })

  const {
    title,
    unit,
    isModalOpen,
    loading,
    titleError,
    unitError,
    currentPage,
  } = pageState

  const handleAddProduct = async () => {
    if (title.trim() === '') {
      setPageState((prevState) => ({
        ...prevState,
        titleError: 'Title is required.',
      }))
      return
    }

    if (unit.trim() === '') {
      setPageState((prevState) => ({
        ...prevState,
        unitError: 'Unit is required.',
      }))
      return
    }
    if (title.trim() !== '' && unit.trim() !== '') {
      setPageState((prevState) => ({ ...prevState, loading: true }))
      try {
        const data = {
          category_id: categoryId,
          title: title,
          unit: unit,
          is_dynamic: 0,
        }

        const response = await clientService.post('product', data)
        console.log(response)
        if (response?.data?.statusCode === 201) {
          getAppData()
          setPageState((prevState) => ({
            ...prevState,
            isModalOpen: false,
            title: '',
            unit: '',
          }))
          toast.success('Product added successfully!')
        }
      } catch (error) {
        console.error('Error adding Product:', error)
        toast.error('Error adding product. Please try again.')
      } finally {
        setPageState((prevState) => ({ ...prevState, loading: false }))
      }
    }
  }

  const handleChangePage = (event, newPage) => {
    setPageState({ ...pageState, currentPage: newPage })
  }

  const rowsPerPage = 10
  const indexOfLastProduct = currentPage * rowsPerPage
  const indexOfFirstProduct = indexOfLastProduct - rowsPerPage
  const currentProducts = _product?.products.slice(
    indexOfFirstProduct,
    indexOfLastProduct,
  )

  return (
    <Container>
      <ToastContainer position="top-right" autoClose={2000} />
      <Button
        variant="contained"
        color="primary"
        sx={{ marginY: 1, width: '100%' }}
        onClick={() => {
          setPageState((prevState) => ({ ...prevState, isModalOpen: true }))
        }}
      >
        Add Product
      </Button>
      <Modal
        open={isModalOpen}
        onClose={() => {
          setPageState((prevState) => ({ ...prevState, isModalOpen: false }))
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            minWidth: 300,
            maxWidth: 600,
            outline: 'none', // Remove modal outline
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 5,
              right: 5,
            }}
            onClick={() =>
              setPageState((prevState) => ({
                ...prevState,
                isModalOpen: false,
              }))
            }
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" gutterBottom>
            Add Product
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Title"
                name="title"
                value={title}
                onChange={(e) => {
                  setPageState((prevState) => ({
                    ...prevState,
                    title: e.target.value,
                    titleError: '',
                  }))
                }}
                fullWidth
                required
                error={!!titleError}
                helperText={titleError}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Unit"
                name="unit"
                value={unit}
                onChange={(e) => {
                  setPageState((prevState) => ({
                    ...prevState,
                    unit: e.target.value,
                    unitError: '', // Clear validation error as user types
                  }))
                }}
                fullWidth
                required // Add required attribute for validation
                error={!!unitError} // Set error state based on validation
                helperText={unitError} // Show error message if validation fails
              />
            </Grid>
          </Grid>
          <Box sx={{ textAlign: 'right', mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddProduct}
            >
              Add Product
            </Button>
          </Box>
        </Box>
      </Modal>

      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
            minHeight: '100vh',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead sx={{ backgroundColor: '#36454F' }}>
                <TableRow>
                  <StyledTableCell sx={{ color: '#fff' }}>No.</StyledTableCell>
                  <StyledTableCell sx={{ color: '#fff' }}>
                    Title
                  </StyledTableCell>
                  <StyledTableCell sx={{ color: '#fff' }}>Unit</StyledTableCell>
                  {/* <StyledTableCell sx={{ color: '#fff' }}>Price</StyledTableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {currentProducts?.length > 0 ? (
                  currentProducts.map((product, index) => (
                    <StyledTableRow key={product.id}>
                      <StyledTableCell>
                        {indexOfFirstProduct + index + 1}
                      </StyledTableCell>
                      <StyledTableCell>{product.title}</StyledTableCell>
                      <StyledTableCell>{product.unit}</StyledTableCell>
                      {/* <StyledTableCell>{product.price}</StyledTableCell> */}
                    </StyledTableRow>
                  ))
                ) : (
                  <StyledTableRow>
                    <StyledTableCell colSpan={4}>
                      <Typography variant="body1" align="center">
                        No products available in this category.
                      </Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {_product?.products?.length > rowsPerPage && (
            <Box
              sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}
            >
              <Pagination
                count={Math.ceil(_product.products.length / rowsPerPage)}
                page={currentPage}
                onChange={handleChangePage}
              />
            </Box>
          )}
        </>
      )}
    </Container>
  )
}

export default Products
