import axios from 'axios'
import { config } from '../config/config'

export const clientService = {
  get: async (endPoint) => {
    var token = localStorage.getItem('token')
    var headers = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    try {
      return await axios.get(config.apiUrl + endPoint, headers)
    } catch (e) {
      return null
    }
  },

  post: async (endPoint, data) => {
    var token = localStorage.getItem('token')
    var headers = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    try {
      const res = await axios.post(config.apiUrl + endPoint, data, headers)
      return {
        status: true,
        data: res.data,
      }
    } catch (e) {
      return {
        status: false,
        data: e?.response?.data.message,
      }
    }
  },

  put: async (endPoint, data) => {
    var token = localStorage.getItem('token')
    var headers = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    try {
      const res = await axios.put(config.apiUrl + endPoint, data, headers)
      return {
        status: true,
        data: res.data,
      }
    } catch (e) {
      return {
        status: false,
        data: e.response.data.errors,
      }
    }
  },
}
