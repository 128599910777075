import { configureStore } from '@reduxjs/toolkit'
import authreducer from './slices/authSlice'
import categoryreducer from './slices/categoriesSlice'

export const store = configureStore({
  reducer: {
    auth: authreducer,
    category: categoryreducer,
  },
})
