import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Login from '../pages/Login/Login'
import Home from '../pages/Home/Home'
import PrivateRoute from './PrivateRoute'
import Categories from '../pages/Categories/Categories'
import Products from '../pages/Product/Products'

const Navigation = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const checkAuth = () => {
    localStorage.getItem('token') ? setIsLoggedIn(true) : setIsLoggedIn(false)
  }

  useEffect(() => {
    checkAuth()
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/login"
          element={<Login setIsLoggedIn={setIsLoggedIn} />}
        />
        <Route
          path="/"
          element={
            <PrivateRoute isLoggedIn={isLoggedIn}>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          path="/categories"
          element={
            <PrivateRoute isLoggedIn={isLoggedIn}>
              <Categories />
            </PrivateRoute>
          }
        />
        <Route
          path="/products"
          element={
            <PrivateRoute isLoggedIn={isLoggedIn}>
              <Categories />
            </PrivateRoute>
          }
        />
        <Route
          path="/products/:categoryId"
          element={
            <PrivateRoute isLoggedIn={isLoggedIn}>
              <Products />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  )
}

export default Navigation
