import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Button,
  Modal,
  Box,
  TextField,
  CircularProgress,
  IconButton,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import Pagination from '@mui/material/Pagination'
import LogoutIcon from '@mui/icons-material/Logout'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, toast } from 'react-toastify'

import { getCategoryData } from '../../store/slices/categoriesSlice'
import { clientService } from '../../utils/Service'

// Define styled components for TableCell and TableRow
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${theme.breakpoints.up('sm')} th`]: {
    fontSize: '1.25rem',
    backgroundColor: '#000', // Customize font size for large screens
  },
  // Add your custom styles here
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const Categories = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [pageState, setPageState] = useState({
    isModalOpen: false,
    categoryName: '',
    loading: false,
    categoryError: '',
    currentPage: 1,
  })

  const {
    isModalOpen,
    categoryName,
    loading,
    categoryError,
    currentPage,
  } = pageState

  const rowsPerPage = 10

  const getAppData = useCallback(async () => {
    dispatch(getCategoryData())
  }, [dispatch])

  useEffect(() => {
    getAppData()
  }, [getAppData])

  const categories = useSelector((state) => state.category.categoryData)

  const handleCategoryClick = (categoryId) => {
    navigate(`/products/${categoryId}`)
  }

  const handleAddCategoryModalOpen = () => {
    setPageState((prevState) => ({ ...prevState, isModalOpen: true }))
  }

  const handleAddCategoryModalClose = () => {
    setPageState((prevState) => ({ ...prevState, isModalOpen: false }))
  }

  const handleAddCategory = async () => {
    if (categoryName.trim() !== '') {
      setPageState((prevState) => ({ ...prevState, loading: true }))
      try {
        const response = await clientService.post('category', {
          title: categoryName,
        })
        if (response?.data?.statusCode === 201) {
          getAppData()
          handleAddCategoryModalClose()
          setPageState((prevState) => ({ ...prevState, categoryName: '' }))
          toast.success('Category added successfully!')
        }
      } catch (error) {
        console.error('Error adding category:', error)
        toast.error('Error adding category. Please try again.')
      } finally {
        setPageState((prevState) => ({ ...prevState, loading: false }))
      }
    } else {
      // Show validation error if category name is empty
      setPageState((prevState) => ({
        ...prevState,
        categoryError: 'Category Name is required.',
      }))
    }
  }

  const handleChangePage = (event, newPage) => {
    setPageState({ ...pageState, currentPage: newPage })
  }

  const indexOfLastRow = currentPage * rowsPerPage
  const indexOfFirstRow = indexOfLastRow - rowsPerPage
  const currentRows = categories?.data?.slice(indexOfFirstRow, indexOfLastRow)

  return (
    <Container>
      <ToastContainer position="top-right" autoClose={2000} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          color="primary"
          sx={{ marginY: 1, width: '95%' }}
          onClick={handleAddCategoryModalOpen}
        >
          Add Category
        </Button>

        <IconButton
          onClick={() => {
            localStorage.removeItem('token')
            navigate('/login')
          }}
          sx={{ marginY: 1 }}
          color="error"
        >
          <LogoutIcon />
        </IconButton>
      </Box>

      <Modal open={isModalOpen} onClose={handleAddCategoryModalClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            minWidth: 300,
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 5,
              right: 5,
            }}
            onClick={handleAddCategoryModalClose}
          >
            <CloseIcon />
          </IconButton>
          <TextField
            label="Category Name"
            value={categoryName}
            onChange={(e) => {
              setPageState((prevState) => ({
                ...prevState,
                categoryName: e.target.value,
                categoryError: '', // Clear validation error as user types
              }))
            }}
            fullWidth
            margin="normal"
            required // Add required attribute for validation
            error={!!categoryError} // Set error state based on validation
            helperText={categoryError}
          />
          <Box sx={{ textAlign: 'right', mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddCategory}
              disabled={loading}
            >
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: 'white',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              )}
              Add Category
            </Button>
          </Box>
        </Box>
      </Modal>

      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
            minHeight: '100vh',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead sx={{ backgroundColor: '#36454F' }}>
                <TableRow>
                  <StyledTableCell sx={{ color: '#fff' }}>No</StyledTableCell>
                  <StyledTableCell sx={{ color: '#fff' }}>
                    Category Name
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentRows?.map((category, index) => (
                  <StyledTableRow
                    key={category.id}
                    onClick={() => handleCategoryClick(category.id)}
                    style={{ cursor: 'pointer' }}
                  >
                    <StyledTableCell>
                      {indexOfFirstRow + index + 1}
                    </StyledTableCell>
                    <StyledTableCell>{category.name}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {categories?.data?.length > rowsPerPage && (
            <Box
              sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}
            >
              <Pagination
                count={Math.ceil(categories?.data?.length / rowsPerPage)}
                page={currentPage}
                onChange={handleChangePage}
              />
            </Box>
          )}
        </>
      )}
    </Container>
  )
}

export default Categories
