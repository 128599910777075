/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'

const authInitialState = {
  authUser: {},
  isLoggedn: false,
  isCompleteProfile: false,
  showLogin: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    setLoggedInUser: (state, action) => {
      state.authUser = action.payload
    },

    setIsLogged: (state, action) => {
      state.isLoggedn = action.payload
    },
    setIsCompleteProfile: (state, action) => {
      state.isCompleteProfile = action.payload
    },
    logoutUser: (state) => {
      state.authUser = {}
      state.isLoggedn = false
      state.isCompleteProfile = false
    },
    setShowLogin: (state, action) => {
      state.showLogin = action.payload
    },
  },
})

export const {
  setLoggedInUser,
  setIsLogged,
  setIsCompleteProfile,
  logoutUser,
  setShowLogin,
} = authSlice.actions

export default authSlice.reducer
