import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { toast, ToastContainer } from 'react-toastify'
import { Container, Typography, TextField, Button, Card } from '@mui/material'

import { clientService } from '../../utils/Service'

const Login = ({ setIsLoggedIn }) => {
  const navigate = useNavigate()

  const [pageState, setPageState] = useState({
    email: 'spy@gmail.com',
    password: '123456',
    emailError: '',
    passwordError: '',
    loading: false,
  })

  const { email, password, emailError, passwordError, loading } = pageState

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      setPageState((prevState) => ({ ...prevState, loading: true }))
      if (!email) {
        setPageState((prevState) => ({
          ...prevState,
          emailError: 'Email is required',
        }))
        return
      }
      setPageState((prevState) => ({
        ...prevState,
        emailError: '',
      }))

      if (!password) {
        setPageState((prevState) => ({
          ...prevState,
          passwordError: 'Password is required',
        }))
        return
      }
      setPageState((prevState) => ({
        ...prevState,
        passwordError: '',
      }))

      const userCredential = {
        email: email,
        password: password,
      }

      const res = await clientService.post('login', userCredential)
      if (res?.data?.statusCode === 200) {
        localStorage.setItem('token', res?.data?.token)
        toast.success('Login Successful.')
        setIsLoggedIn(true)
        navigate('/')
      }
    } catch (error) {
      console.log(error)
      toast.error('Something went wrong. Please try again later.')
    } finally {
      setPageState((prevState) => ({ ...prevState, loading: false }))
    }
  }

  return (
    <Container
      maxWidth="xs"
      style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ToastContainer position="top-right" autoClose={2000} />
      <Card
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 30,
        }}
      >
        <Typography variant="h4" gutterBottom>
          Login
        </Typography>
        <form
          onSubmit={handleSubmit}
          style={{ width: '100%', marginTop: '1rem' }}
        >
          <TextField
            label="Email"
            variant="outlined"
            margin="normal"
            type="email"
            fullWidth
            required
            value={email}
            onChange={(e) => {
              setPageState((prevState) => ({
                ...prevState,
                email: e.target.value,
              }))
            }}
            error={!!emailError}
            helperText={emailError}
          />
          <TextField
            label="Password"
            variant="outlined"
            margin="normal"
            type="password"
            fullWidth
            required
            value={password}
            onChange={(e) => {
              setPageState((prevState) => ({
                ...prevState,
                password: e.target.value,
              }))
            }}
            error={!!passwordError}
            helperText={passwordError}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{ marginTop: '1rem', width: '100%' }}
            disabled={loading}
          >
            Login
          </Button>
        </form>
      </Card>
    </Container>
  )
}

export default Login
